import { combineReducers } from "redux";
import categoryReducer from "./categoryReducer";
import locationReducer from "./locationReducer";
import appointmentsReducer from "./appointmentsReducer";
import authenticationReducer from "./authenticationReducer";
import bookAgainPageReducer from "./dashboardReducer";
import previousSalonVisitedReducer from "./previousSalonVisitedReducer";
import pastAppointmentsReducer from "./pastAppointmentsReducer";
import updateUserLocationReducer from "./updateUserLocationReducer";
import upcomingAppointmentsReducer from ".//upcomingAppointmentsReducer";
import stylistReducer from "./stylistReducer";
import confirmAppointmentReducer from "./confirmAppointmentReducer";
import reserveAppointmentReducer from "./reserveAppointmentReducer";
import dashboardFlyoutReducer from "./dashboardFlyoutReducer";
import deeplinkReducer from "./deeplinkReducer";
import deeplinkAppointmentReducer from "./deeplinkAppointmentReducer";
import deeplinkLocationReducer from "./deepLinkLocationReducer";
import deleteApptSoftBookingReducer from "./deleteApptSoftBookigReducer";
import deeplinkUserAgentReducer from "./deepLinkUserAgentReducer";
import  stylistFlowReducer from"./stylistFlowReducer";





export default combineReducers({
  category: categoryReducer,
  location: locationReducer,
  appointments: appointmentsReducer,
  authentication: authenticationReducer,
  bookagain: bookAgainPageReducer,
  previousSalonVisited: previousSalonVisitedReducer,
  updateUserLocation: updateUserLocationReducer,
  pastAppointments: pastAppointmentsReducer,
  upcomingAppointments:upcomingAppointmentsReducer,
  dashboardFlyout:dashboardFlyoutReducer,
  stylist: stylistReducer,
  confirmappt: confirmAppointmentReducer,
  reserveAppt: reserveAppointmentReducer,
  deepLinkInfo: deeplinkReducer,
  deepLinkAppointmentInfo: deeplinkAppointmentReducer,
  deepLinkLocationInfo: deeplinkLocationReducer,
  deleteapptsoftbook: deleteApptSoftBookingReducer,
  deepLinkUserAgent: deeplinkUserAgentReducer,
  stylistFlow:stylistFlowReducer,
});
