import { getGraphqlResponseData } from "../../middleware/middleware";
import {
  availableStylist,
  stylistCombo,
} from "../../graphql/query/availableStylist";
import { isArray } from "lodash";
import {
  FETCH_STYLIST_CARD_DATA,
  FETCH_STYLIST_CARD_DATA_SUCCESS,
  FETCH_STYLIST_CARD_DATA_FAILURE,
  FETCH_STYLIST_CARD_DATA_WITHOUT_TIME,
  FETCH_STYLIST_CARD_DATA_UPDATE_ERROR,
} from "./types";

import {
  getSelectedStylistData,
  getSelectedStylistDataWithoutDate,
  getDiasbleTime,
} from "../../utils/helper/helper";
import { getSelectedStylist } from "../../actions/appointments/appointments";

export const fetchStylistData = (data) => {
  return {
    type: FETCH_STYLIST_CARD_DATA,
    data,
  };
};

export const fetchStylistDataSuccess = (data) => {
  return {
    type: FETCH_STYLIST_CARD_DATA_SUCCESS,
    data,
  };
};

export const fetchStylistDataFailure = (data) => {
  return {
    type: FETCH_STYLIST_CARD_DATA_FAILURE,
    data,
  };
};

export const hasTimeAvaialble = (data) => {
  return {
    type: FETCH_STYLIST_CARD_DATA_WITHOUT_TIME,
    data,
  };
};

export const updateErrorMessage = (data, message) => {
  return {
    type: FETCH_STYLIST_CARD_DATA_UPDATE_ERROR,
    data,
    message,
  };
};

const modifiedData = (data) => {
  let res = data.map((each) =>
    isArray(each)
      ? each.filter(
          (element) =>
            element?.availableDays && Object.keys(element.availableDays).length
        )
      : Object.keys(each.availableDays).length
  );
  const getData = res.filter((eachval) => eachval.length > 0);

  return getData;
};

export const getStylistDataWithMonth =
  ({
    selectedStore,
    selectedServices,
    startDay,
    endDay,
    selectedStylist,
    isdateSelected = false,
    editAppointmentId,
    hasImage = false,
    isStylistFlow = false
  }) =>
  async (dispatch) => {
    dispatch(fetchStylistData(true));

    const payload = {
      method: "post",
      url: `/graphql`,
      headers: {
        "Content-Type": "application/json",
      },
      data: availableStylist(
        selectedStore,
        selectedServices,
        startDay,
        endDay,
        selectedStylist,
        null,
        editAppointmentId,
        isStylistFlow
      ),
    };
    const graphqlData = await getGraphqlResponseData(payload);

    if (
      graphqlData?.status === 200 &&
      graphqlData?.data?.data?.stylistWithAvailableDays
    ) {
      const getData = modifiedData(
        graphqlData.data.data.stylistWithAvailableDays
      );

      if (isdateSelected) {
        const selectedStylistData = getSelectedStylistData(
          getData,
          isdateSelected,
          selectedStylist
        );
        dispatch(getSelectedStylist(selectedStylistData));
      }
      if (hasImage) {
        const selectedStylistData = getSelectedStylistDataWithoutDate(
          getData,
          selectedStylist
        );
        dispatch(getSelectedStylist(selectedStylistData));
      }

      dispatch(fetchStylistDataSuccess(getData));
      dispatch(fetchStylistData(false));
    } else {
      dispatch(fetchStylistData(false));

      dispatch(fetchStylistDataSuccess(null));
      dispatch(fetchStylistDataFailure(true));
    }
  };

export const getStylistDataWithoutTime =
  ({ selectedStore, selectedServices, startDay, timeZone }) =>
  async (dispatch) => {
    dispatch(fetchStylistData(true));
    dispatch(hasTimeAvaialble(false));
    const payload = {
      method: "post",
      url: `/graphql`,
      headers: {
        "Content-Type": "application/json",
      },
      data: stylistCombo(selectedStore, selectedServices, startDay, timeZone),
    };
    const graphqlData = await getGraphqlResponseData(payload);
    if (graphqlData?.status === 200 && graphqlData?.data?.data?.stylistCombo) {
      dispatch(fetchStylistData(false));
      dispatch(hasTimeAvaialble(true));
      dispatch(fetchStylistDataSuccess(graphqlData.data.data.stylistCombo));
    } else {
      dispatch(fetchStylistData(false));
      dispatch(fetchStylistDataFailure(true));
      dispatch(fetchStylistDataSuccess(null));
      dispatch(hasTimeAvaialble(false));
    }
  };

export const getStylistData =
  ({
    selectedStore,
    selectedServices,
    startDay,
    endDay,
    isEditable = false,
    selectedStylist,
    partoftheday = null,
    restrictTeammate = true
  }) =>
  async (dispatch) => {
    dispatch(fetchStylistData(true));
    dispatch(hasTimeAvaialble(false));
    let partofthedays;
    if (partoftheday && partoftheday.length > 0) {
      if (partoftheday.length === 3) {
        partofthedays = null;
      } else {
        partofthedays = partoftheday;
      }
    } else if (partoftheday === "all") {
      partofthedays = null;
    } else {
      partofthedays = null;
    }
    const payload = {
      method: "post",
      url: `/graphql`,
      headers: {
        "Content-Type": "application/json",
      },
      data: availableStylist(
        selectedStore,
        selectedServices,
        startDay,
        endDay,
        selectedStylist,
        partofthedays,
        null,
        null,
        restrictTeammate
      ),
    };
    const payloadWithoutTime = {
      method: "post",
      url: `/graphql`,
      headers: {
        "Content-Type": "application/json",
      },
      data: stylistCombo(selectedStore, selectedServices, startDay, null, null, restrictTeammate),
    };
    const [graphqlData, graphqlDataWithoutTime] = await Promise.all([getGraphqlResponseData(payload), getGraphqlResponseData(payloadWithoutTime)]);
    let hasError = false;
    if (
      graphqlData?.status === 200 &&
      graphqlData?.data?.data?.stylistWithAvailableDays
    ) {
      if (isEditable) {
        const getData = modifiedData(
          graphqlData.data.data.stylistWithAvailableDays
        );
        const getTimedata = getDiasbleTime(
          getData,
          selectedStore?.timezone,
          startDay
        );
        const stylistCombo = graphqlDataWithoutTime?.data?.data?.stylistCombo;

        const finalStylistList = stylistCombo ? getTimedata.concat((stylistCombo).filter((stylist) => (getTimedata.findIndex(function (stylistTime) {
          for (let index = 0; index < stylist.length; index++) {
            if (stylistTime[index].teammateId !== stylist[index].teammateId) {
              return false;
            }
          }
          return true;
        })) < 0)) : getTimedata;

        hasError = finalStylistList?.length === 0 && graphqlDataWithoutTime?.data?.errors && graphqlDataWithoutTime?.data?.errors[0]
        if (getData?.length === 0) {
          dispatch(hasTimeAvaialble(true));
        }
        dispatch( hasError ? updateErrorMessage(true, graphqlDataWithoutTime?.data?.errors[0]?.message) : fetchStylistDataSuccess(finalStylistList));
      } else {
        dispatch(
          fetchStylistDataSuccess(graphqlData.data.data.stylistWithAvailableDays)
        );
      }
      if (!hasError) {
        dispatch(fetchStylistData(false));
      }
    } else {
      dispatch(fetchStylistData(false));
      dispatch(fetchStylistDataSuccess(null));
      dispatch(fetchStylistDataFailure(true));
      graphqlDataWithoutTime?.data?.errors && graphqlDataWithoutTime?.data?.errors[0] && dispatch(updateErrorMessage(true, graphqlDataWithoutTime?.data?.errors[0]?.message));
    }
  };

export const removeStylistcard = () => async (dispatch) => {
  dispatch(fetchStylistDataSuccess(null));
};
