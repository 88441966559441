import React from "react";
import { useEffect, useRef } from "react";
import Button from "web-common-ssr/src/views/components/Button/Button";
import Modal from "web-common-ssr/src/views/components/Modal/Modal";
import Text from "web-common-ssr/src/views/components/Text/Text";
import { getText } from "../../utils/helper/helper";
import Link_Huge from "web-common-ssr/src/views/components/Link_Huge/Link_Huge";
import "./FlyoutModal.scss";

const FlyoutModal = ({ handleCancelPopup }) => {
  const modalRef = useRef();
  useEffect(() => {
    modalRef.current &&
      modalRef.current.showModal &&
      modalRef.current.showModal();
  });

  const handleContinue = () => {
    const aptCreateTimeInMs = sessionStorage.getItem(getText('TIMER_KEY'));
    if(aptCreateTimeInMs){
      sessionStorage.removeItem(getText('TIMER_KEY'));
    }
  }

  return (
    <Modal ref={modalRef} callBackFunc={handleCancelPopup}>
      <div className="message-dialog">
        <Text htmlTag="p" textStyle="body-2" spacerValue="02">
          {getText("dashboard.modalMsg")}
        </Text>
        <div className="flyoutModal-container">
          <div>
            <Link_Huge url="/dashboard">
              {/* <div>{getText("dashboard.continue")}</div> */}
              <div className="continue_button">      
            <Button
              outline
              label={getText("dashboard.continue")}
              onClick={handleContinue}
            /></div>
            </Link_Huge>
          </div>
          <div>
            <Button
              likeLink
              label={getText("dashboard.cancel")}
              onClick={() => handleCancelPopup()}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FlyoutModal;